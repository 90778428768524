<template>
    <div class="container">
        <div class="title"><span class="numStyle">100000</span>+投资顾问在使用智策投顾平台</div>
        <div class="main"></div>
        <button @click="handleClick">免费试用</button>
    </div>
</template>

<script>
export default {
    methods:{
        handleClick() {
            this.$store.commit('sign/setVisible', true)
        }
    }
}
</script>

<style scoped>
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 714px;
}
.title{
    font-family: PingFangSC-Medium, PingFang SC;;
    font-size: 36px;
}
.title .numStyle{
    font-size: 60px;
}
.main{
    margin: 40px auto;
    width: 1240px;
    height: 276px;
    background: url('~@/assets/invest/footer.png') no-repeat center/90%;
}
button{
    width: 400px;
    height: 52px;
    background: #2E5082;
    border-radius: 8px;
    margin: 0 auto;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
</style>