<template>
    <div class="container">
        <div class="content">
            <div class="bgBlock"></div>
            <img src="@/assets/invest/section1-item1.png" v-show="activeIndex==1" alt="">
            <img src="@/assets/invest/section1-item2.png" v-show="activeIndex==2" alt="">
            <img src="@/assets/invest/section1-item3.png" v-show="activeIndex==3" alt="">
            <img src="@/assets/invest/section1-item4.png" v-show="activeIndex==4" alt="">
            <div class="button-wrap">
                <div class="button" @click="handleClick(1)" :class="{active: activeIndex==1}">1</div>
                <div class="button" @click="handleClick(2)" :class="{active: activeIndex==2}">2</div>
                <div class="button" @click="handleClick(3)" :class="{active: activeIndex==3}">3</div>
                <div class="button" @click="handleClick(4)" :class="{active: activeIndex==4}">4</div>
            </div>
            <div class="label">
                智策投顾平台，基金销售一站式解决方案
            </div>
            <div class="introduce" v-show="activeIndex==1">
                <p class="title">选基金，6大筛选功能</p>
                <p class="text">高级筛选、智能筛选、股票倒查、基金排行、优选基金、基金异动等6大筛选功能，满足顾客多样化需求</p>
                <el-button round size="mini" @click="handleClickBtn">免费试用</el-button>
            </div>
            <div class="introduce" v-show="activeIndex==2">
                <p class="title">卖基金，智能提取基金与经理亮点</p>
                <p class="text">从绩效、风险、持仓、风格、归因五大维度智能提取基金与经理亮点，支持自定义制作报告，自主编辑文案，一键生成PDF。</p>
                <el-button round size="mini" @click="handleClickBtn">免费试用</el-button>
            </div><div class="introduce" v-show="activeIndex==3">
                <p class="title">换基金，分析客户持仓做精准推荐</p>
                <p class="text">通过持仓分析可以了解到客户持仓分布以及持仓表现，智能提供定投止投、止盈、ETF择时、基金替换、配置优化等建议</p>
                <el-button round size="mini" @click="handleClickBtn">免费试用</el-button>
            </div><div class="introduce" v-show="activeIndex==4">
                <p class="title">配基金，智能组合轻松实现资产配置</p>
                <p class="text">基于客户偏好，结合营销基金池，由AI算法给出个性化资产配置方案。配置完成后可通过组合分析、组合优化得到最优方案。</p>
                <el-button round size="mini" @click="handleClickBtn">免费试用</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: 1,
        }
    },
    methods:{
        handleClick(index) {
            this.activeIndex = index
        },
        handleClickBtn() {
            this.$store.commit('sign/setVisible', true)
        }
    }
}
</script>

<style scoped>
.container{
    height: 725px;
    position: relative;
}
.container .content{
    width: 1340px;
    height: 100%;
    position: relative;
    text-align: left;
}
.content img{
    width: 750px;
    /* height: 601px; */
    position: absolute;
    top: 120px;
    left: -115px;
    z-index: 100;
}
.content .bgBlock{
    width: 434px;
    height: 434px;
    background-color: #FF6673;
    border-radius: 56px;
    transform: rotate(60deg);
    position: absolute;
    top: 95px;
    left: 125px;
    z-index: 1;
}
.content .button-wrap .button{
    width: 32px;
    height: 32px;
    background-color: #DDDDDD;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    margin: 20px 0;
}
.button-wrap{
    font-family: 'pinfang_xiti', sans-serif;
    position: absolute;
    top: 320px;
    left: 665px;
    color: #999999;
}
.button-wrap .button.active{
    background-color: #FF6673;
    color: #FFFFFF;
}
.label{
    font-size: 36px;
    font-weight: 400;
    white-space: nowrap;
    color: #666666;
    position: absolute;
    top: 240px;
    left: 665px;
}
.introduce{
    width: 648px;
    height: auto;
}
.introduce .title{
    font-family: PingFangSC-Medium, PingFang SC;;
    font-size: 36px;
    color: #333333;
    position: absolute;
    white-space: nowrap;
    top: 330px;
    left: 715px;
}
.introduce .text{
    font-family: PingFangSC-Regular, PingFang SC;;
    font-size: 18px;
    color: #666666;
    position: absolute;
    top: 390px;
    left: 715px;
    width: 520px;
    text-align: left;
}
.introduce button{
    position: absolute;
    top: 485px;
    left: 715px;
    font-size: 18px;
    border: 1px solid #333333;
    color: #333333;
    padding: 11px 37px;
}
</style>