<template>
    <div class="container">
        <div class="title">基金经理评测，新发基金销售利器</div>
        <div class="text">销售新发基金 看基金经理评测就够了</div>
        <div class="main">
            <div class="section section1">
                <img src="@/assets/invest/section4-icon1.png" alt="">
                <p class="label">综合分析</p>
                <p class="content">快速了解基金经理表现，看基金经理能力雷达图、行业偏好、获奖情况三项分析就能一目了然</p>
            </div>
            <div class="section section2">
                <img src="@/assets/invest/section4-icon2.png" alt="">
                <p class="label">收益分析</p>
                <p class="content">综合基金经理旗下管理基金数据，看其综合收益表现与排名，了解基金经理博取收益能力</p>
            </div>
            <div class="section section3">
                <img src="@/assets/invest/section4-icon3.png" alt="">
                <p class="label">风险分析</p>
                <p class="content">6种市场行情下的情景分析、历史回撤表现，帮你判断基金经理的风险控制能力</p>
            </div>
            <div class="section section4">
                <img src="@/assets/invest/section4-icon4.png" alt="">
                <p class="label">持仓分析</p>
                <p class="content">基金经理旗下基金持仓基本雷同，看基金经理综合持仓，预测新发基金可能持仓</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    height: 714px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(#4E7CEE, #8FAEFE);
    position: relative;
    z-index: 999;
}
.title{
    font-family: 'pingfang_zhongheiti', sans-serif;
    font-size: 36px;
    color: #ffffff;
}
.text{
    color: #ffffff;
    font-family: PingFangSC-Regular, PingFang SC;;
    font-size: 18px;
    margin-bottom: 40px;
}
.main{
    width: 1300px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
}
.section{
    width: 350px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: no-repeat url('~@/assets/border1.png') 30px 5px;
}
.section .label{
    font-family: 'pingfang_zhongheiti', sans-serif;
    font-size: 20px;
    color: #ffffff;
}
.section .content{
    font-family: PingFangSC-Regular, PingFang SC;;
    font-size: 16px;
    color: #ffffff;
    width: 194px;
    margin-top: 10px;
    text-align: left;
}
.section img{
    width: 101px;
    height: 101px;
    margin: 0 auto;
}
.section4{
    background: center no-repeat url('~@/assets/border0.png');
}
</style>