<template>
    <div class="container">
        <div class="title">报告系统，维护客户关系变轻松</div>
        <div class="content">{{title}}报告</div>
        <div class="swiper">
            <el-carousel :interval="4000" type="card" height="500px" @change="handleChange">
                <el-carousel-item v-for="item in 5" :key="item">
                    <img :src='"@/assets/invest/swiper-item"+item+".png"' alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            titleList:[
                '组合分析',
                '基金比较',
                '基金评测',
                '基金公司评测',
                '基金经理评测'
            ],
            title:''
        }
    },
    methods:{
        handleChange(index){
            this.title = this.titleList[index]
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    margin-top: 2.11rem;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;

    margin-bottom: -80px;
}
.title{
    font-family: PingFangSC-Medium, PingFang SC;;
    font-size: 36px;
    color: #333333;
}
.content{
    font-family: PingFangSC-Regular, PingFang SC;;
    font-size: 24px;
    color: #666666;
}
.swiper{
    height: 556px;
    width: 1000px;
    margin: 0 auto;
}
.el-carousel__item img {
    width: 438px;
    height: 556px;
    margin:0 auto;
}

.el-carousel__item:nth-child(2n) {
    // background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
    // background-color: #ffffff;
}
/deep/ .el-carousel__arrow--right{
    background:url("~@/assets/invest/next.png");
    background-size: cover;
}
/deep/ .el-icon-arrow-left, /deep/ .el-icon-arrow-right{
    display: none;
}
/deep/ .el-carousel__arrow--left{
    background:url("~@/assets/invest/prev.png");
    background-size: cover;
}
</style>