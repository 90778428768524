<template>
    <div class="wrap">
       <my-header></my-header>
       <section-1></section-1>
       <section-2></section-2>
       <section-3></section-3>
       <section-4></section-4>
       <my-footer></my-footer>
    </div>
</template>

<script>
import MyFooter from './components/MyFooter.vue'
import MyHeader from './components/MyHeader.vue'
import Section1 from './components/Section1.vue'
import Section2 from './components/Section2.vue'
import Section3 from './components/Section3.vue'
import Section4 from './components/Section4.vue'
export default {
    components: {
        MyFooter,
        MyHeader,
        Section1,
        Section2,
        Section3,
        Section4
    },

}
</script>

<style scoped>
header{
    /* margin-top: 100px; */
    height: 488px;
    background-color: aqua;
}
section{
    height: 714px;
    background-color: blueviolet;
}
</style>